<template>
  <a-form
    layout="vertical"
    :model="form"
    ref="formRef"
    name="update_device_setting"
    :rules="validationsRules()"
    class="device-setting-form"
    @finish="onSubmit"
  >
    <div>
      <a-form-item label="Send Device Status Interval" name="statusInterval">
        <a-input-number
          id="statusInterval"
          placeholder="Enter send device status interval"
          style="width: 100%"
          v-model:value="form.statusInterval"
          :min="5"
          :max="120"
        />
      </a-form-item>
    </div>
    <div class="text-right">
      <a-button @click="onCancel">
        Cancel
      </a-button>
      <a-button
        type="primary"
        html-type="submit"
        :loading="isLoading"
        class="ml-2"
      >
        Update
      </a-button>
    </div>
  </a-form>
</template>
<script>
import DeviceService from 'src/services/device';

export default {
  emits: ['cancel'],
  props: ['orgName'],
  inject: ['toast'],

  data() {
    return {
      isLoading: false,
      form: {}
    };
  },
  methods: {
    validationsRules() {
      return {
        statusInterval: [
          {
            required: true,
            trigger: '[submit,cancel]',
            message: 'Send device status interval is required'
          }
        ]
      };
    },
    async onSubmit() {
      this.isLoading = true;
      const payload = {
        status_send_interval: this.form.statusInterval
      };
      const [error, data] = await DeviceService.updateAllDeviceSettingsInOrg(
        this.orgName,
        payload
      );
      this.isLoading = false;
      if (error) {
        this.toast.error('Failed to update device setting.');
        return;
      }
      this.toast.success('Device settings updated successfully.');
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
