export default [
    {
        title: 'Name',
        dataIndex: 'Org_name'
    },
    {
        title: 'Logo',
        dataIndex: 'logo'
    },
    {
        title: 'Address',
        dataIndex: 'Address'
    },
    {
        title: 'No Of Employees',
        dataIndex: 'Number_of_emp',
        width: '15%'
    },
    {
        title: 'Description',
        dataIndex: 'Description'
    },
    {
        title: 'Cap FPS',
        dataIndex: 'fps_cap',
        width: '15%'
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        width: '10%'
    }
]